import React, { useEffect, useState } from "react";
import PdfViewer from "../components/PdfViewer";
import HeroSection from "../components/HeroSection";
import CategorySection from "../components/CategorySection";
import AboutUs from "../components/AboutUs";
import ContactForm from "../components/ContactUs";
import { useLocation } from "react-router";
const HomePage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const searchTermFromPath = pathSegments[pathSegments.length - 1];
    const decodedSearchTerm = decodeURIComponent(searchTermFromPath); // Decode the search term
    setSearchTerm(decodedSearchTerm);
  }, [location.pathname]);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  return (
    <>
      <PdfViewer />
      <HeroSection onSearch={handleSearch} searchTerm={searchTerm} />
      <div className="container mx-auto">
        <CategorySection searchTerm={searchTerm} />
      </div>
      <AboutUs />
      <ContactForm />
    </>
  );
};

export default HomePage;
