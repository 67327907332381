import images from "./images";
import pdf from "../assets/pdf/rainyware.pdf";
export const categories = [
  {
    title: "Stainless Steel Soap Holder",
    image: images.steelSoapHolder,
  },
  {
    title: "Stainless Steel Tumblr Holder",
    image: images.steelTumblrRod,
  },
  {
    title: "Stainless Steel Towel Ring",
    image: images.steelTowelRing,
  },
  {
    title: "Stainless Steel Towel Rod",
    image: images.steelTowelRod,
  },
  {
    title: "Stainless Steel Towel Rack",
    image: images.steelTowelRack,
  },
  {
    title: "DSR",
    image: images.dsr,
  },
  {
    title: "SSR",
    image: images.ssr,
  },
  {
    title: "Corner Set",
    image: images.cornerSet,
  },
  {
    title: "Glass Soap Dish",
    image: images.glassSoap,
  },
  {
    title: "Glass Tumbler Holder",
    image: images.glassTumbler,
  },
  {
    title: "Stainless Steel Wall Hook",
    image: images.wallHook,
  },
  {
    title: "Triangle Bath Set",
    image: images.triangleBathSet,
  },
  {
    title: "Platinum Bath Set",
    image: images.platinumBathSet,
  },
  {
    title: "Square Bath Set",
    image: images.squareBathSet,
  },
  {
    title: "Diamond Bath Set",
    image: images.diamongBathSet,
  },
];

export const RAINYWARE_PDF = pdf;

export const email = "hkrainyware1975@gmail.com";