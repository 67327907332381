import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import CategorySection from "./CategorySection";

const Product = () => {
  const { productId } = useParams();
  const decodedProductId = decodeURIComponent(productId);
  const location = useLocation();
  const navigate = useNavigate();

  const [product, setProduct] = useState(null);

  useEffect(() => {
    // Fetch product details from state
    const { state } = location;
    setProduct(state);

    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="flex flex-col items-center justify-center w-full">
      {product && (
        <div className="my-4 flex flex-col items-center justify-center w-full">
          <h2 className="text-5xl font-semibold text-center">
            {product.title}
          </h2>
          <img
            src={product.image}
            alt={product.title}
            className="lg:w-1/3 aspect-auto my-2 p-2"
          />
        </div>
      )}
      <CategorySection searchTerm={decodedProductId} />
    </div>
  );
};

export default Product;
