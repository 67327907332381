import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import images from "./constants/images";
ReactDOM.render(
  <ChakraProvider>
    <BrowserRouter>
      <Helmet>
        <meta
          name="description"
          content="Discover a wide range of bathroom accessories in Aligarh at our online store. From stylish soap holders and sturdy towel racks to sleek glass tumbler holders and modern wall hooks, we offer a variety of steel bathroom fixtures to elevate your space. Explore our collection of DSR and SSR sets, corner sets, and contemporary glass soap dishes. Find everything you need for your bathroom decor, including luxury towel rings, towel rods, and bathroom hardware. Shop our selection of home accessories and bathroom essentials, from fittings to organization and storage solutions. Elevate your bathroom with our stylish and designer products, designed to enhance functionality and aesthetics. Browse our range of modern, contemporary, and luxury bathroom accessories today."
        />
        <meta
          name="keywords"
          content="Aligarh, bathroom accessories, Soap Holder, Tumblr Holder, Steel, Towel Ring, Towel Rod, Towel Rack, DSR, SSR, Corner Set, Glass Soap Dish, Glass Tumbler Holder, Wall hook, bathroom fixtures, bathroom decor, bathroom hardware, bathroom products, home accessories, bathroom essentials, bathroom supplies, bathroom fittings, bathroom organization, bathroom storage, modern bathroom accessories, contemporary bathroom accessories, luxury bathroom accessories, stylish bathroom accessories, designer bathroom accessories
          "
        />
        <meta property="og:title" content={"Rainyware"} />
        <meta
          property="og:description"
          content={
            "Discover a wide range of bathroom accessories in Aligarh at our online store. From stylish soap holders and sturdy towel racks to sleek glass tumbler holders and modern wall hooks, we offer a variety of steel bathroom fixtures to elevate your space. Explore our collection of DSR and SSR sets, corner sets, and contemporary glass soap dishes. Find everything you need for your bathroom decor, including luxury towel rings, towel rods, and bathroom hardware. Shop our selection of home accessories and bathroom essentials, from fittings to organization and storage solutions. Elevate your bathroom with our stylish and designer products, designed to enhance functionality and aesthetics. Browse our range of modern, contemporary, and luxury bathroom accessories today."
          }
        />
        <meta property="og:image" content={images.logo} />
        <meta property="og:url" content={"https://www.rainyware.in"} />
        <meta property="og:type" content="website" />
      </Helmet>
      <App />
    </BrowserRouter>
  </ChakraProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
