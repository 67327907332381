import React, { useState } from "react";
import { email as emailAddress } from "../constants/data";
const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [phone, setPhone] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(
      `Name: ${name}\nEmail: ${email}\nPhone: ${phone}`
    )}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="flex justify-center mt-3 w-full flex-col" id="contact">
      <div className="w-full flex justify-center">
        <form
          className="bg-gradient-to-b  rounded-lg shadow-2xl p-8 lg:w-5/6"
          onSubmit={handleSubmit}
        >
          <h3 className="text-gray-900 text-3xl font-semibold mb-6">
            Let's Build a Relation
          </h3>
          <input
            required
            id="name"
            type="text"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="bg-gray-200 text-gray-800 border border-gray-300 rounded-md px-4 py-2 mb-4 focus:outline-none focus:border-blue-500 w-full"
          />
          <input
            required
            id="email"
            type="email"
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="bg-gray-200 text-gray-800 border border-gray-300 rounded-md px-4 py-2 mb-4 focus:outline-none focus:border-blue-500 w-full"
          />
          <textarea
            required
            id="subject"
            type="text"
            placeholder="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="bg-gray-200 text-gray-800 border border-gray-300 rounded-md px-4 py-2 mb-4 focus:outline-none focus:border-blue-500 w-full"
          />
          <input
            required
            id="phone"
            type="tel"
            placeholder="Your Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="bg-gray-200 text-gray-800 border border-gray-300 rounded-md px-4 py-2 mb-4 focus:outline-none focus:border-blue-500 w-full"
          />
          <button
            type="submit"
            className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
          >
            Send Message
          </button>
        </form>
      </div>
      <div className="w-full flex justify-center">
        {" "}
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.613088652492!2d78.07731121441063!3d27.85525292857381!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3974a30fae840849%3A0xf878d2499f145241!2sRAINYWARE%20(%20Bathroom%20Accessories%20Manufacturer%20)!5e0!3m2!1sen!2sin!4v1647487549351!5m2!1sen!2sin"
          className="w-5/6 h-96"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactForm;
