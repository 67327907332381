import React from "react";

function AboutUs() {
  return (
    <div className="p-10 md:flex md:flex-col md:justify-center md:w-1/2 md:m-auto">
      <h2 className="text-center  text-4xl font-bold mb-4">About Us</h2>
      <p className="text-lg mb-4 text-center">
        Rainyware is a visionary brand that blends the art of design with the
        precision of engineering. Founded on the principles of innovation and
        excellence, we strive to redefine the boundaries of what's possible. Our
        journey began with a single goal: to create products that inspire and
        empower. Through tireless dedication and relentless pursuit of
        perfection, we've established ourselves as pioneers in the industry.
      </p>
      <p className="text-lg mb-4 text-center">
        At Rainyware, we believe that great design is more than just aesthetics;
        it's about functionality, usability, and impact. That's why every
        product we create is meticulously crafted to deliver an unparalleled
        experience. From the initial concept to the final product, we pay
        attention to every detail to ensure that our customers receive nothing
        but the best. With a focus on quality, durability, and sustainability,
        we're committed to making a positive difference in the world.
      </p>
      <p className="text-lg mb-4 text-center">
        Our philosophy is simple: to innovate, inspire, and empower. We're
        constantly pushing the boundaries of what's possible, exploring new
        ideas, and challenging the status quo. Through collaboration,
        creativity, and a relentless pursuit of excellence, we're shaping the
        future of design and technology. Join us on this journey as we continue
        to transform ideas into reality and make a lasting impact on the world.
      </p>
    </div>
  );
}

export default AboutUs;
