import React, { useState } from "react";
import { useNavigate } from "react-router";

const CategoryCard = ({ image, title }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate();

  function extractPrefix(inputString) {
    const match = inputString.match(/[a-zA-Z]+(?:\s[a-zA-Z]+)*/);
    if (match) {
      let prefix = match[0].replace(/^\w/, (c) => c.toUpperCase());
      // If the prefix is still in camel case (e.g., "SteelSoap"), insert a space
      if (!prefix.includes(" ")) {
        const words = prefix.match(/[A-Z][a-z]+/g);
        prefix = words.join(" ");
      }
      return prefix;
    } else {
      return inputString.toLowerCase();
    }
  }
  
  const handleClick = () => {
    // If title is null, redirect to image name
    if (title === null) {
      const imageName = image.substring(image.lastIndexOf("/") + 1);
      const actualImageName = extractPrefix(imageName);
      navigate(`/product/${actualImageName}`, {
        state: {
          title: actualImageName,
          image,
        },
      });
    } else {
      navigate(`/product/${title}`, {
        state: {
          title,
          image,
        },
      });
    }
  };

  return (
    <div
      className="flex flex-col gap-2 relative cursor-pointer"
      onClick={handleClick}
    >
      <img
        src={image}
        className="lg:w-[230px] md:w-[230px] w-[150px] aspect-square object-cover rounded-2xl"
      />
      <h2 className="font-semibold text-sm break-words max-w-[150px]">
        {title}
      </h2>
      {showTooltip && (
        <div className="absolute top-full left-0 z-10">
          <img
            src={image}
            className="w-full scale-125 object-contain rounded-2xl shadow-lg"
            alt={title}
          />
        </div>
      )}
    </div>
  );
};

export default CategoryCard;
