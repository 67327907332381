import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { RAINYWARE_PDF } from "../constants/data";
import { CircularProgress, Flex, Button } from "@chakra-ui/react";
import { FaArrowLeft, FaArrowRight, FaDownload } from "react-icons/fa";

function PDF(props) {
  return (
    <div className="md:w-1/2 flex flex-col items-center">
      {" "}
      {/* Updated styles */}
      <div className="mb-4 relative p-2 max-w-full">
        <Document
          file={RAINYWARE_PDF}
          onLoadSuccess={props.onDocumentLoadSuccess}
          loading={<CircularProgress color="green.300" size="40px" />}
        >
          <Page
            pageNumber={props.pageNumber}
            scale={window.innerWidth < 768 ? 0.5 : 1} // Adjust scale for smaller screens
            renderAnnotationLayer={false}
            renderTextLayer={false}
            onLoadSuccess={() => props.setLoading(false)}
            noData={true}
          />
        </Document>
        {/* {props.loading && (
          <Flex
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            <CircularProgress color="green.300" size="40px" />
          </Flex>
        )} */}
      </div>
      <div className="flex justify-center">
        <Button
          onClick={props.prevPage}
          disabled={props.pageNumber <= 1 || props.loading}
          colorScheme="blue"
          leftIcon={<FaArrowLeft />}
          mr={2}
        >
          Prev
        </Button>
        <Button
          onClick={props.nextPage}
          disabled={props.pageNumber >= props.numPages || props.loading}
          colorScheme="blue"
          rightIcon={<FaArrowRight />}
          mr={2}
        >
          Next
        </Button>
        <Button
          onClick={props.handleDownload}
          colorScheme="blue"
          rightIcon={<FaDownload />}
        >
          Download
        </Button>
      </div>
    </div>
  );
}

const PdfViewer = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setLoading(false);
  };

  const nextPage = () => {
    if (pageNumber < numPages) setPageNumber(pageNumber + 1);
  };

  const prevPage = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = RAINYWARE_PDF;
    link.download = "rainyware.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const handleResize = () => {
      setPageNumber(1);
      setLoading(true);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className=" flex justify-center" id="about">
      <div className="flex flex-col md:flex-row justify-center lg:p-10 bg-white lg:w-5/6 mt-4">
        <PDF
          numPages={numPages}
          pageNumber={pageNumber}
          loading={loading}
          setLoading={setLoading}
          onDocumentLoadSuccess={onDocumentLoadSuccess}
          nextPage={nextPage}
          prevPage={prevPage}
          handleDownload={handleDownload}
        />
      </div>
    </div>
  );
};

export default PdfViewer;
