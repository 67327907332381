import React from "react";
import {
  AiOutlinePhone,
  AiOutlineWhatsApp,
  AiOutlineMail,
} from "react-icons/ai";
import { FaLinkedin, FaGlobe } from "react-icons/fa";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const contactNumbers = ["9927166477", "7017909560", "6370994290"];
  const whatsAppNumbers = ["6370994290", "7983230034"];
  const email = "hkrainyware1975@gmail.com";
  const creatorName = "Deepank Pushpad";
  const linkedInProfile =
    "https://www.linkedin.com/in/deepank-pushpad-747664188/";

  const renderContactInfo = (type, items) => (
    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-4 sm:mb-0">
      <h4 className="text-lg font-semibold mb-4">{type}</h4>
      {items.map((item, index) => (
        <p key={index}>
          {type === "Phone" && <AiOutlinePhone className="inline-block mr-2" />}
          {type === "WhatsApp" && (
            <AiOutlineWhatsApp className="inline-block mr-2" />
          )}
          {type === "E-Mail" && <AiOutlineMail className="inline-block mr-2" />}
          {type === "Website" && <FaGlobe className="inline-block mr-2" />}
          {type === "LinkedIn" && <FaLinkedin className="inline-block mr-2" />}
          {type === "LinkedIn" ? (
            <a
              href={linkedInProfile}
              className="text-blue-400 hover:text-yellow-400"
            >
              {item}
            </a>
          ) : (
            <a
              href={`tel:${item}`}
              className="text-blue-400 hover:text-yellow-400"
            >
              {item}
            </a>
          )}
        </p>
      ))}
    </div>
  );

  return (
    <footer className="bg-gray-800 text-gray-300 py-8 mt-3">
      <div className="container mx-auto flex flex-wrap justify-center">
        {renderContactInfo("Phone", contactNumbers)}
        {renderContactInfo("WhatsApp", whatsAppNumbers)}
        {renderContactInfo("E-Mail", [email])}
      </div>
      <div className="container mx-auto mt-8 text-center">
        <p className="text-sm">
          &copy; {currentYear} Rainyware. All rights reserved. | Created by{" "}
          <a
            className="text-blue-400 hover:text-yellow-400"
            href={"https://www.linkedin.com/in/deepank-pushpad-747664188/"}
            target="_blank"
          >
            {creatorName}{" "}
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
