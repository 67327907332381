import React from "react";

const PrimaryButton = (props) => {
  return (
    <button
      className="bg-yellow-400 text-red-800 px-2 py-2 rounded-md font-bold"
      {...props}
    >
      {props.title}
    </button>
  );
};

export default PrimaryButton;
