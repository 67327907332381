import React, { useState, useEffect } from "react";
import PrimaryButton from "../UI/Button/Button";
import images from "../constants/images";
import { FaSearch } from "react-icons/fa";

const HeroSection = ({ onSearch, searchTerm: initialSearchTerm }) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

  useEffect(() => {
    setSearchTerm(initialSearchTerm);
  }, [initialSearchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    onSearch(event.target.value); // Pass the search term to the parent component
  };

  return (
    <div className={`relative lg:p-6 p-4 flex justify-center`}>
      <img
        src={images.herosectionBg}
        className="lg:w-5/6 lg:h-[30rem] md:h-[20rem] sm:h-[15rem] object-cover rounded-2xl"
        alt="Hero Background"
      />
      <div className="absolute lg:bottom-4 lg:left-36 bottom-2 left-2 p-6 bg-blur rounded-xl">
        <h1 className="text-xl lg:text-5xl font-bold text-white bg-transparent backdrop-filter backdrop-blur-lg rounded-xl p-3">
          Name You Can Always Remember
        </h1>
        {/* Search bar */}
        <div className="flex flex-col mt-4 lg:flex-row lg:w-1/2 bg-white rounded-lg justify-between p-2 w-full lg:h-14 h-12">
          <div className="flex items-center ">
            <FaSearch
              className="text-yellow-400 "
              size={20}
              fontWeight={"300"}
            />
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="px-2 py-2 ml-2 focus:outline-none focus:border-yellow-400 w-full "
            />
          </div>
          <div>
            <PrimaryButton
              title="Search"
              className="px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 focus:outline-none focus:bg-yellow-600 lg:block hidden"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
