// CategorySection.js
import React from "react";
import CategoryCard from "../UI/CategoryCard/CategoryCard";
import { categories } from "../constants/data";
import images from "../constants/images";

const CategorySection = ({ searchTerm }) => {
  // Function to normalize strings by removing spaces and converting to lowercase
  const normalizeString = (str) => str.replace(/\s/g, "").toLowerCase();

  // Tokenize the search term by splitting it into words
  const searchTokens = searchTerm.trim().toLowerCase().split(/\s+/);

  // Filter categories based on the search term
  const filteredCategories = categories.filter((cat) => {
    // Normalize the category title
    const normalizedTitle = normalizeString(cat.title);
    // Check if any of the search tokens match part of the normalized title
    return searchTokens.some((token) => normalizedTitle.includes(token));
  });

  // Filter images based on the search term
  const filteredImages = Object.keys(images).filter((key) => {
    // Normalize the image key
    const normalizedKey = normalizeString(key);
    // Check if any of the search tokens match part of the normalized key
    return searchTokens.some((token) => normalizedKey.includes(token));
  });

  return (
    <div className="flex justify-center">
      <div className="flex flex-wrap lg:w-5/6 w-full justify-center gap-4">
        {/* Render CategoryCards for filteredCategories */}
        {filteredCategories.map((cat) => (
          <CategoryCard key={cat.id} title={cat.title} image={cat.image} />
        ))}
        {/* Render CategoryCards for filteredImages */}
        {searchTerm &&
          filteredImages.map((key) => {
            // Check if the image key is not the same as any cat.image
            if (!filteredCategories.some((cat) => cat.image === images[key])) {
              return (
                <CategoryCard key={key} title={null} image={images[key]} />
              );
            }
            return null; // Skip rendering if the image is already in cat.image
          })}
      </div>
    </div>
  );
};

export default CategorySection;
