import React, { useEffect } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import HomePage from "./Pages/HomePage";
import Product from "./components/Product";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import images from "./constants/images";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top whenever the location changes
  }, [location]);

  return (
    <>
      <Navbar />
      <Routes className="bg-zinc-100">
        <Route path="/" element={<HomePage />} />
        <Route path="/product/:productId" element={<Product />} />
        <Route path="*" element={<HomePage />} />
      </Routes>
      <FloatingWhatsApp
        style={{ height: "0" }}
        phoneNumber="7983230034"
        accountName="Rainyware"
        avatar={images.logo}
        statusMessage={"Name You Can Always Remember"}
        styles={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
        }}
      />
      <Footer />
    </>
  );
};

export default App;
